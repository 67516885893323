import React from "react"
import Layout from "~/components/common/Layout"
import { QuizResults } from "~/components/page/Quiz"
import { getHeaderTitles, getNextLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 6
  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: getNextLink(myPage, mySubpage),
      }}
    >
      <QuizResults
        quizNumber={"TWO"}
        nextLink={getNextLink(myPage, mySubpage)}
      ></QuizResults>
    </Layout>
  )
}

export default withProgress(Page, 2, 6)
